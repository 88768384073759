// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/Index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/Privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-success-js": () => import("./../../../src/templates/Success.js" /* webpackChunkName: "component---src-templates-success-js" */)
}

